
import Vue from "vue";
import { mask } from "vue-the-mask";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import DatePicker from "@/components/form-components/DatePicker.vue";
import ContactsPicker from "@/components/form-components/ContactsPicker.vue";
import BankAccount from "@/components/form-components/BankAccount.vue";

export default Vue.extend({
  name: "Personal",

  components: {
    DatePicker,
    BankAccount,
    ContactsPicker,
  },

  directives: { mask },

  props: {
    user: {
      required: true,
      default: () => ({}),
    },
    profile: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data: () => ({
    rules,
    credentials: store.getters["authentication/credentials"],
    document: { title: "", document: null } as any,
    dialog: false as boolean,
    loading: false as boolean,
    errorMessages: {} as any,
    selects: {
      states: [] as Array<any>,
      genders: [
        {
          value: "m",
          text: "M",
        },
        {
          value: "f",
          text: "F",
        },
      ] as Array<any>,
      work_positions: [] as Array<any>,
      departments: [] as Array<any>,
      branches: [] as Array<any>,
      types: [] as Array<SelectComponentInterface>,
      banks: [] as Array<SelectComponentInterface>,
      users: [] as Array<SelectComponentInterface>,
      organizationForms: [] as Array<SelectComponentInterface>,
    },
    model: {
      banks: [] as Array<any>,
      type: "juridical",
      contacts: [] as Array<any>,
      client_bank_accounts: [] as Array<any>,
    } as any,
    menu: false as boolean,
    oldModel: "" as string,
  }),

  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler() {
        // this.model = this.profile;
      },
    },
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      },
    },
  },

  computed: {
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    isJuridical() {
      return this.model.type === "juridical";
    },
    disabledSubmit() {
      return this.oldModel === JSON.stringify(this.model);
    },
  },

  async beforeMount() {
    try {
      const [item, banks, types, organizationForms] = await Promise.all([
        this.$API.users().getProfile(),
        this.$API.banks().getList(),
        this.$API.clients().getClientTypes(),
        this.$API.organizationForms().getList(),
        this.$API.banks().getList(),
      ]);

      this.model = item;
      this.oldModel = JSON.stringify(item);
      this.selects = {
        ...this.selects,
        banks,
        types,
        organizationForms,
      };
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          if (this.isJuridical) {
            delete model.identity_card;
            delete model.identity_card_issued;
            delete model.identity_card_date;
          } else {
            delete model.administrator_name;
            delete model.vat_code;
          }

          model.client_bank_accounts = model.client_bank_accounts.map(
            (element: any) => ({ ...element, bank_id: element.bank.id })
          );
          this.model = await this.$API.users().updateProfile(model);
          this.oldModel = JSON.stringify(this.model);
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    save(date: string) {
      (this.$refs.menu as any).save(date);
    },
    addBankAccount() {
      this.model.client_bank_accounts.push({
        bank: { id: null },
        account: "",
        id: null,
      });
    },
    removeBankAccount(index: number) {
      this.model.client_bank_accounts.splice(index, 1);
    },
  },
});
