
import { mask } from "vue-the-mask";
import rules from "@/services/helpers/validation-rules";
import Vue from "vue";

export default Vue.extend({
  name: "Security",

  directives: { mask },

  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    security: {} as any,
    shownPassword: {
      new: false,
      confirm: false
    } as any,
    errorMessages: {} as any,
    selects: {
      doctors: [] as Array<SelectComponentInterface>
    }
  }),

  computed: {
    isSamePassword(): any {
      return (
        this.security.password === this.security.password_confirmation ||
        this.$t("users.form.validation.not_match")
      );
    }
  },

  methods: {
    async changePassword() {
      try {
        await this.$API
          .profile()
          .changePassword(Number(this.user.id), this.security);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("profile.alert.success")
        );
        this.security = {
          ...this.security,
          password: undefined,
          old_password: undefined,
          password_confirmation: undefined
        };

        document.location.href = "/";
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
      }
    },
    getPasswordType(key: string) {
      if (this.shownPassword[key]) {
        return "text";
      }
      return "password";
    },
    togglePasswordType(key: string) {
      this.shownPassword[key] = !this.shownPassword[key];
    }
  }
});
