
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "BankAccount",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [] as Array<any>,
    },
    banks: {
      type: Array,
      required: true,
      default: () => [] as Array<any>,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    errorMessages: {
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    rules,
  }),

  methods: {
    addRow(): void {
      this.$emit("add", {});
    },
    deleteRow(index: number): void {
      this.$emit("remove", index);
    },
  },
});
