
import Vue from "vue";
import PhonePicker from "@/components/form-components/PhonePicker.vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "ContactsPicker",

  components: { PhonePicker },

  props: {
    value: {
      required: false,
      type: Array,
      default: () => [] as Array<any>
    },
    type: {
      required: false,
      type: String,
      default: () => "phone"
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    errorMessages: {
      required: false,
      default: () => ({})
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    isCreate: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    rules,
    values: [] as Array<any>,
    items: [] as Array<any>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (Array.isArray(this.values)) {
          for (const item of this.value) {
            if (!this.values.includes(item as any)) {
              this.values.push(item as any);
            }
          }
        }
      }
    }
  },

  computed: {
    contacts(): any {
      return {
        phones: this.values.filter(item => item.type === "phone"),
        emails: this.values.filter(item => item.type === "email")
      };
    }
  },

  mounted() {
    if (this.isCreate) {
      if (!this.values.some((item: any) => item.type === "phone")) {
        this.addContact("phone");
      }

      if (!this.values.some((item: any) => item.type === "email")) {
        this.addContact("email");
      }
    }
  },

  methods: {
    changeData(): void {
      this.values = [...this.contacts.phones, ...this.contacts.emails];

      const uniqueContacts: Array<any> = [];

      for (const item of this.values) {
        if (
          !uniqueContacts.some((contact: any) => contact.value === item.value)
        ) {
          uniqueContacts.push(item);
        }
      }

      this.$emit("input", uniqueContacts);
    },
    addContact(type: string) {
      this.values.push({ value: "", type: type });
    },
    removeItem(index: number, type: string) {
      this.contacts[type].splice(index, 1);
      this.changeData();
    },
    getError(item: any): any {
      const contactIndex = this.values.indexOf(item);
      if (contactIndex !== -1) {
        return (
          (this.errorMessages as any)[`contacts.${contactIndex}.value`] || ""
        );
      }
      return "";
    }
  }
});
